var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"form_add_or_update",staticClass:"repeater-form",attrs:{"method":"post","action":"javascript:void(0)"},on:{"submit":_vm.takeAttendance}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Attendance Date'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"required":"","name":"attendance_date","placeholder":"select date","state":errors.length > 0 ? false:null},model:{value:(_vm.attendance_date),callback:function ($$v) {_vm.attendance_date=$$v},expression:"attendance_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),[_vm._l((_vm.stds),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Cadet No'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('student_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":_vm.cadetIdByID(item.id),"readonly":"","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('cadet no')}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Name'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":_vm.studentNameByID(item.id),"readonly":"","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('student name')}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Attendance'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('attendance'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mb-3",attrs:{"required":"","options":_vm.attendance_statuses,"value-field":"id","name":'status_ids['+item.id+']',"text-field":"alias","disabled-field":"notEnabled"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),(_vm.stds.length>0)?_c('b-button',{attrs:{"variant":"outline-primary","type":"submit"}},[_vm._v(" Submit ")]):_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }