<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)" @submit="takeAttendance"
              ref="form_add_or_update"
              class="repeater-form" >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Attendance Date') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('date')"
                  rules="required"
              >
                <b-form-datepicker v-model="attendance_date" required
                                   name="attendance_date" placeholder="select date"
                                   :state="errors.length > 0 ? false:null"></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <template>
          <!-- Row Loop -->
          <b-row
              v-for="(item, index) in stds"
              :id="item.id"
              :key="index"
              ref="row"
          >
            <!-- Mark from -->
            <b-col md="3">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cadet No') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('student_id')"
                    rules="required"
                >
                  <b-form-input
                      :value="cadetIdByID(item.id)"
                      readonly
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cadet no')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Name') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('name')"
                    rules="required"
                >
                  <b-form-input
                      :value="studentNameByID(item.id)"
                      readonly
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('student name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Attendance') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('attendance')"
                    rules=""
                >
                  <b-form-radio-group required
                      :options="attendance_statuses"
                      class="mb-3"
                      value-field="id"
                      :name="'status_ids['+item.id+']'"
                      text-field="alias"
                      disabled-field="notEnabled"
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12"><hr></b-col>
          </b-row>
          <b-button v-if="stds.length>0" variant="outline-primary" type="submit">
            Submit
          </b-button>
        </template>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BFormGroup, BFormInput,BFormRadioGroup,
  BForm,BRow, BCol,BCardText,BFormDatepicker,
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import apiCall from "@/libs/axios";
export default {
  name:'TakeAttendance',
  components: {
    BCardCode,BButton,BFormGroup, BFormInput,BFormDatepicker,BFormRadioGroup,
    BForm,BRow, BCol,BCardText,ValidationProvider, ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    classes:{required:true},
    // version:{required:true},
    // shift:{required:true},
    wing:{required:true},
    student_group:{required:true},
    section:{required:true},
    academic_year:{required:true},
    subject:{required:true},
  },
  data() {
    return {
      required,
      attendance_date:null,
      selected:[],
    }
  },
  methods:{
    takeAttendance(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.form_add_or_update);
          data.append('classes_id',this.classes);
          data.append('version_id', 1);
          data.append('shift_id', 1);
          data.append('wing_id',this.wing);
          data.append('student_group_id',this.student_group);
          data.append('section_id',this.section);
          data.append('subject_id',this.subject);
          data.append('academic_year_id',this.academic_year);
          apiCall.post('/employee/student/attendance/store', data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              //this.$store.dispatch('GET_ALL_EMPLOYEE_UPLOADED_MARK');
            } else this.$toaster.error(response.data.message);
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      });
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    /*get this employee students*/
    stds(){
      return this.$store.getters.students.filter(item=>{
        if(item.classes_id=== parseInt(this.classes) && item.version_id===parseInt(this.version) &&
            item.shift_id===parseInt(this.shift) && item.wing_id===parseInt(this.wing) &&
            item.student_group_id===parseInt(this.student_group) && item.section_id===parseInt(this.section) &&
            item.academic_year_id===parseInt(this.academic_year)
        ) return item;
      });
    },
    ...mapGetters(['students','studentIdByID','studentNameByID','attendance_statuses', 'cadetIdByID']),
  },
  created() {
    if(this.students.length <1) this.$store.dispatch('GET_ALL_STUDENT');
    if(this.attendance_statuses.length <1) this.$store.dispatch('GET_ALL_ATTENDANCE_STATUS');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>